import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  transform(value: string, terms: string[]): string {
    if (!value || !terms || terms.length === 0) {
      return value;
    }

    let result = value;
    const escapedTerms = terms.map(term => term.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'));

    escapedTerms.forEach(term => {
      const regex = new RegExp(`\\b(${term})\\b`);
      result = result.replace(regex, '<span class="highlight">$1</span>');
    });

    return result;
  }
}